/* bodycard {
    font-family: sans-serif;
    margin: 0 auto;
    padding: 0px;
    max-width: 0px;
    min-width: 0px;
    background-color: #dfe6e9;
    text-align: center;
  }
   */
  .headingcard {
    text-align: center;
    font-size: 2em;
    color: #2d3436;
    margin: 20px 0 20px;
  }
  
  .cardsup {
    border-radius: 25px;
    position: relative;
    padding: 25px 15px;
    background-color: #81ecec;
    margin: 10px 0;
    height: 250px;
    box-shadow: 0 2px 5px #ccc;
    text-align: left;
  }
  
  .cardchemical {
    border-radius: 25px;
    position: relative;
    padding: 25px 15px;
    background-color: #DFA7D3;
    margin: 10px 0;
    height: 250px;
    box-shadow: 0 2px 5px #ccc;
    text-align: left;
  }
  .top {
    border-radius: 25px 25px 0 0;
    height: 100px;
    width: 100%;
    background-color: #AED6F1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
  }
  
  .name {
    font-size: 28px;
    color: #2d3436;
    display: flex;
    flex: 1;
    margin: 2% 5px 0;
  }
  .name1 {
    font-size: 28px;
    color: #2d3436;
    display: flex;
    flex: 1;
    margin: 2% 5px 0;
  }
  .card img {
    margin: 30px 20px 0 0;
  }
  
  .circle-img {
    border-radius: 50%;
    border: 1px solid #fff;
    width: 120px;
    height: 120px;
  }
  
  .bottom {
    margin-top: 80px;
  }
  
  .info {
    margin: 5px 0;
    color: #2C3E50;
  }
  /*  for show image */
  
  .mytooltip {
    position: relative;
    display: inline;
    /* z-index: 999 */
  }
  .mytooltip .tooltip-item {
    /* background:#ffffff; */
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 0 10px
  }
  /* .mytooltip .tooltip-item::after {
    content: '';
  }  */
  /* .mytooltip:hover .tooltip-item::after {
    pointer-events: auto;
  } */
  .mytooltip .tooltip-content {
    position: absolute;
    z-index: 1;
    width: 300px;
   bottom:50%;
    /* bottom: 100%; */
    left:0%;
    /* margin: 0 0 20px -180px; */
    margin: 8px 0 0 0px;
        /* border: solid; */
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    -webkit-box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    background: #ffffff;
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }
  /* .mytooltip .tooltip-content::after {
    content: '';
  } */
  .mytooltip:hover .tooltip-content {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg)
  }
  .mytooltip .tooltip-content img {
    position: relative;
    /* height: 140px; */
    display: block;
    float: left;
    margin-right: 10px;
    /* border: solid; */
  }
  .mytooltip .tooltip-text {
    font-size: 14px;
    line-height: 24px;
    display: block;
    padding: 1.31em 1.21em 1.21em 0;
    color: #fff
  }




/* 
  * {
    margin: 0px; 
    padding: 0px; 
    box-sizing: border-box;
   }
   
   body, html {
    height: 100%;
    font-family: sans-serif;
   }
   
  
   
   a {
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
     -o-transition: all 0.4s;
     -moz-transition: all 0.4s;
   }
  
   
   
   a:hover {
    text-decoration: none;
   }
   
  
   
   
   p {margin: 0px;}
   
   ul, li {
    margin: 0px;
    list-style-type: none;
   }

   
   iframe {
    border: none !important;
   } 
   

   

 
   .limiter {
     width: 100%;
     margin: 0 auto;
   }
   
   .container-table100 {
    width: 100%;
  min-height: 100vh;
  background: #c850c0;
  background: -webkit-linear-gradient(45deg, #4158d0, #c850c0);
  background: -o-linear-gradient(45deg, #4158d0, #c850c0);
  background: -moz-linear-gradient(45deg, #4158d0, #c850c0);
  background: linear-gradient(45deg, #4158d0, #c850c0);
   
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-wrap: wrap;
     padding: 33px 30px;
   }
   
   .wrap-table100 {
     width:100%;
   }
   
   table {
     border-spacing: 1;
     border-collapse: collapse;
     background: rgb(255, 251, 251);
     border-radius: 15px;
     overflow: hidden;
     width: 100%;
     margin: 0 auto;
     position: relative;
   }
   table * {
     position: relative;
   }
   table td, table th {
     padding-left: 20px;
   }
  
   
   table thead tr {
     height: 50px;
     background: #4c4c4c;
   }
   table tbody tr {
     height: 70px;
   }
   table tbody tr:last-child {
     border: 0;
   }
   table td, table th {
     text-align: left;
   }
   table td.l, table th.l {
     text-align: right;
   }
   table td.c, table th.c {
     text-align: center;
   }
   table td.r, table th.r {
     text-align: center;
   }
   
  
   
   .table100-head th{
     font-family: OpenSans-Regular;
     font-size: 18px;
     color: rgb(243, 233, 233);
     line-height: 1.2;
     font-weight: unset;
   }
  
   
   tbody tr:nth-child(even) {
     background-color: #e5e5e5;
     color: #0e0202;
   }
   
   tbody tr {
     font-family: OpenSans-Regular;
     font-size: 15px;
     background-color: #f5f5f5;
     color: #030000;
     line-height: 1.2;
     font-weight: unset;
   }
   
   tbody tr:hover {
     color: #0e0909;
     background-color: #e8ded2;
     cursor: pointer;
   }
   
   .column1 {
     width: 260px;
     padding-left: 40px;
   }
   
   .column2 {
     width: 160px;
   }
   
   .column3 {
     width: 245px;
   }
   
   .column4 {
     width: 110px;
     text-align: right;
   }
   
   .column5 {
     width: 170px;
     text-align: right;
   }
   
   .column6 {
     width: 222px;
     text-align: right;
     padding-right: 62px;
   }
   
   
   @media screen and (max-width: 992px) {
     table {
       display: block;
     }
     table > *, table tr, table td, table th {
       display: block;
     }
     table thead {
       display: none;
     }
     table tbody tr {
       height: auto;
       padding: 37px 0;
     }
     table tbody tr td {
       padding-left: 40% !important;
       margin-bottom: 24px;
     }
     table tbody tr td:last-child {
       margin-bottom: 0;
     }
     table tbody tr td:before {
       font-family: OpenSans-Regular;
       font-size: 14px;
       color: #1a1c20;
       line-height: 1.2;
       font-weight: unset;
       position: absolute;
       width: 40%;
       left: 30px;
       top: 0;
     }
     
     
   
     .column4,
     .column5,
     .column6 {
       text-align: left;
     }
   
     .column4,
     .column5,
     .column6,
     .column1,
     .column2,
     .column3 {
       width: 100%;
     }
   
     tbody tr {
       font-size: 14px;
     }
   }
   
   @media (max-width: 576px) {
     .container-table100 {
       padding-left: 15px;
       padding-right: 15px;
     }
   } */